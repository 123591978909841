import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import {
  getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut,
  GoogleAuthProvider, setPersistence, browserLocalPersistence, signInWithRedirect,
  getRedirectResult, sendEmailVerification, sendPasswordResetEmail
} from 'firebase/auth';
import { FirebaseApp } from '@angular/fire/app';

import { Storage } from '@capacitor/storage';
import { User } from './interface/user';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  afAuth: any;
  afStore: any;
  authUser;
  constructor(
    private firebaseApp: FirebaseApp,
    public router: Router,
    public ngZone: NgZone,
    public userService: UserService) {
  }

  checkRedirect() {
    const auth = getAuth(this.firebaseApp);
    return getRedirectResult(auth)
      .then((result) => {
        if (!result) {
          return false;
        }
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        this.updateUserData(user.uid, user.email, user.displayName, user.photoURL);
        return true;
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(`${errorCode}: ${errorMessage}`);
        return false;
      });
  }

  signInWithEmail(email: string, password: string) {
    const auth = getAuth(this.firebaseApp);
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        this.authUser = user;
        if (!user.emailVerified) {
          this.router.navigate(['./email-verification']);
          return false;
        }
        this.userService.updateUserData({ uid: user.uid } as User);
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(`${errorCode}: ${errorMessage}`);
        return false;
      });
  }

  sendEmailVerification() {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'https://app.tortee.my/profile',
      iOS: {
        bundleId: 'my.tortee.match'
      },
      android: {
        packageName: 'my.tortee.match',
        installApp: true,
        minimumVersion: '12'
      },
      dynamicLinkDomain: 'tortee.page.link'
    };
    sendEmailVerification(this.authUser, actionCodeSettings);
  }

  signUpWithEmail(displayName: string, email: string, password: string) {
    const auth = getAuth(this.firebaseApp);
    setPersistence(auth, browserLocalPersistence);
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        this.authUser = user;
        this.updateUserData(user.uid, user.email, displayName);
        this.sendEmailVerification();
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(`${errorCode}: ${errorMessage}`);
        return false;
      });
  }

  signInWithGoogle() {
    const auth = getAuth(this.firebaseApp);
    setPersistence(auth, browserLocalPersistence);
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return signInWithRedirect(auth, provider);
  }

  async signOut() {
    await this.userService.removeToken();
    const auth = getAuth(this.firebaseApp);
    this.userService.detach();
    Storage.clear();
    return signOut(auth).then(() => {
    }).catch((error) => {
      alert(error);
    });
  }

  isLoggedIn() {
    const auth = getAuth(this.firebaseApp);
    console.log(auth.currentUser);
    return !!auth.currentUser;
  }

  resetPwd(email) {
    const auth = getAuth(this.firebaseApp);
    return sendPasswordResetEmail(auth, email)
      .then(() => true)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(`${errorCode}: ${errorMessage}`);
        return false;
      });

  }

  getAuthUser() {
    return new Promise((resolve, reject) => {
      const auth = getAuth(this.firebaseApp);
      auth.onAuthStateChanged(async (_user) => {
        resolve(_user);
      });
    });
  }

  private updateUserData(uid, email, displayName, photoURL = 'assets/default-avatar.png') {
    const user = {
      uid,
      email,
      displayName,
      photoURL
    };
    this.userService.updateUserData(user);
  }

}

