import { Injectable } from '@angular/core';
import { User } from './interface/user';
import { Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot,
   setDoc, writeBatch } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user$: Observable<User>;
  userUid: string;
  user: User;

  constructor() {
    this.register();
  }

  async updateUserData(user: User, rewrite = false) {
    this.userUid = user.uid;
    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
    this.user$ = new Observable(subscriber => {
      onSnapshot(userRef, (userDoc) => {
        this.user = userDoc.data() as User;
        subscriber.next(this.user);
      });
    });

    if (rewrite) {
      const partialUser: any = {
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        telegram: user.telegram,
        messenger: user.messenger,
        whatsapp: user.whatsapp,
        email: user.email,
      };
      const batch = writeBatch(db);
      const mentorQuery = await getDocs(collection(db, `users/${user.uid}/mentors`));
      mentorQuery.forEach((mentorSnapshot) => {
        const mentor = mentorSnapshot.data();
        const menteeId = mentor.subjectId + user.uid;
        batch.update(doc(db, `users/${mentor.uid}/mentees/${menteeId}`), partialUser);
      });

      const menteeQuery = await getDocs(collection(db, `users/${user.uid}/mentees`));
      menteeQuery.forEach((menteeSnapshot) => {
        const mentee = menteeSnapshot.data();
        const mentorId = mentee.subjectId + user.uid;
        batch.update(doc(db, `users/${mentee.uid}/mentors/${mentorId}`), partialUser);
      });

      batch.commit();
    }

    Storage.set({
      key: 'uid',
      value: user.uid,
    });
    setDoc(userRef, user, { merge: !rewrite });
  }

  async getCustomUser(uid: string) {
    const db = getFirestore();
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async register() {
    if (this.user$ !== undefined) {
      return;
    }
    const { value } = await Storage.get({ key: 'uid' });
    const uid = value;
    this.userUid = uid;
    const db = getFirestore();
    const userRef = doc(db, 'users', uid);
    this.user$ = new Observable(subscriber => {
      onSnapshot(userRef, (userDoc) => {
        this.user = userDoc.data() as User;
        subscriber.next(this.user as User);
      });
    });
  }

  detach() {
    this.user$ = null;
  }

  getUser(): Observable<User> {
    this.register();
    return this.user$;
  }

  async removeToken() {
    if(!this.user.notificationTokens){
      return;
    }
    const db = getFirestore();
    const { value } = await Storage.get({ key: 'notificationToken' });
    console.log(this.user);
    delete this.user.notificationTokens[value];
    const userRef = doc(db, 'users', this.userUid);
    await setDoc(userRef, this.user, { merge: true });
  }
}
