import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.authService.getAuthUser() as any;
    if (!user) {
      this.router.navigate(['/login']);
      return false;
    }
    if(!user.emailVerified){
      this.router.navigate(['/email-verification']);
      return false;
    }
    return true;
  }
}
