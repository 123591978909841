import { Component, NgZone } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { browserLocalPersistence, initializeAuth } from 'firebase/auth';
import { Network } from '@capacitor/network';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router, private zone: NgZone,
    private firebaseApp: FirebaseApp, public alertCtrl: AlertController) {
    this.initializeApp();
    this.init();
    StatusBar.setStyle({ style: Style.Light });

    Network.getStatus().then(status=>{
      this.processNetworkStatus(status);
    });

    Network.addListener('networkStatusChange', status => {
      this.processNetworkStatus(status);
    });
  }

  processNetworkStatus(status){
    if(!status.connected){
      this.showAlert('You are not connected to the Internet');
    }
  }

  init() {
    if (Capacitor.isNativePlatform) {
      initializeAuth(this.firebaseApp,{persistence: browserLocalPersistence});
    }
  }

  async showAlert(message) {
    await this.alertCtrl.create({
      header: message,
      buttons: [
        { text: 'Close', handler: () => { } }
      ]
    }).then(res => res.present());
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            console.log(event.url);
            const slug = event.url.split('tortee.my').pop();
            console.log(slug);

            if (slug) {
                this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    });
}
}
